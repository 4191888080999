// Default SPP Font Family

@mixin metropolis-thin {
  font-family: Metropolis, sans-serif;
  font-weight: 100;
}

@mixin metropolis-thin-italic {
  font-family: Metropolis, sans-serif;
  font-weight: 100;
  font-style: italic;
}

@mixin metropolis-extra-light {
  font-family: Metropolis, sans-serif;
  font-weight: 200;
}

@mixin metropolis-extra-light-italic {
  font-family: Metropolis, sans-serif;
  font-weight: 200;
  font-style: italic;
}

@mixin metropolis-light {
  font-family: Metropolis, sans-serif;
  font-weight: 300;
}

@mixin metropolis-light-italic {
  font-family: Metropolis, sans-serif;
  font-weight: 300;
  font-style: italic;
}

@mixin metropolis-italic {
  font-family: Metropolis, sans-serif;
  font-weight: 400;
  font-style: italic;
}

@mixin metropolis-regular {
  font-family: Metropolis, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin metropolis-medium {
  font-family: Metropolis, sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin metropolis-medium-italic {
  font-family: Metropolis, sans-serif;
  font-weight: 500;
  font-style: italic;
}

@mixin metropolis-semi-bold {
  font-family: Metropolis, sans-serif;
  font-weight: 600;
  font-style: normal;
}

@mixin metropolis-semi-bold-italic {
  font-family: Metropolis, sans-serif;
  font-weight: 600;
  font-style: italic;
}

@mixin metropolis-bold {
  font-family: Metropolis, sans-serif;
  font-weight: 700;
  font-style: normal;
}

@mixin metropolis-bold-italic {
  font-family: Metropolis, sans-serif;
  font-weight: 700;
  font-style: italic;
}

@mixin metropolis-extra-bold {
  font-family: Metropolis, sans-serif;
  font-weight: 800;
  font-style: normal;
}

@mixin metropolis-extra-bold-italic {
  font-family: Metropolis, sans-serif;
  font-weight: 800;
  font-style: italic;
}

@mixin metropolis-black {
  font-family: Metropolis, sans-serif;
  font-weight: 900;
  font-style: normal;
}

@mixin metropolis-black-italic {
  font-family: Metropolis, sans-serif;
  font-weight: 900;
  font-style: italic;
}

// Default SPP Font

@mixin spp-thin {
  @include metropolis-thin;
}

.spp-thin {
  @include spp-thin;
}

@mixin spp-thin-italic {
  @include metropolis-thin-italic;
}

.spp-thin-italic {
  @include spp-thin-italic;
}

@mixin spp-extra-light {
  @include metropolis-extra-light;
}

.spp-extra-light {
  @include spp-extra-light;
}

@mixin spp-extra-light-italic {
  @include metropolis-extra-light-italic;
}

.spp-extra-light-italic {
  @include spp-extra-light-italic;
}

@mixin spp-light {
  @include metropolis-light;
}

.spp-light {
  @include spp-light;
}

@mixin spp-light-italic {
  @include metropolis-light-italic;
}

.spp-light-italic {
  @include spp-light-italic;
}

@mixin spp-regular {
  @include metropolis-regular;
}

.spp-regular {
  @include spp-regular;
}

@mixin spp-italic {
  @include metropolis-italic;
}

.spp-italic {
  @include spp-italic;
}

@mixin spp-medium {
  @include metropolis-medium;
}

.spp-medium {
  @include spp-medium;
}

@mixin spp-medium-italic {
  @include metropolis-medium-italic;
}

.spp-medium-italic {
  @include spp-medium-italic;
}

@mixin spp-semi-bold {
  @include metropolis-semi-bold;
}

.spp-semi-bold {
  @include spp-semi-bold;
}

@mixin spp-semi-bold-italic {
  @include metropolis-semi-bold-italic;
}

.spp-semi-bold-italic {
  @include spp-semi-bold-italic;
}

@mixin spp-bold {
  @include metropolis-bold;
}

.spp-bold {
  @include spp-bold;
}

@mixin spp-bold-italic {
  @include metropolis-bold-italic;
}

.spp-bold-italic {
  @include spp-bold-italic;
}

@mixin spp-extra-bold {
  @include metropolis-extra-bold;
}

.spp-extra-bold {
  @include spp-extra-bold;
}

@mixin spp-extra-bold-italic {
  @include metropolis-extra-bold-italic;
}

.spp-extra-bold-italic {
  @include spp-extra-bold-italic;
}

@mixin spp-black {
  @include metropolis-black;
}

.spp-black {
  @include spp-black;
}

@mixin spp-black-italic {
  @include metropolis-black-italic;
}

.spp-black-italic {
  @include spp-black-italic;
}

// Font Sizes

@mixin f1px {
  font-size: 0.0625rem;
}
@mixin f2px {
  font-size: 0.125rem;
}
@mixin f3px {
  font-size: 0.1875rem;
}
@mixin f4px {
  font-size: 0.25rem;
}
@mixin f5px {
  font-size: 0.3125rem;
}
@mixin f6px {
  font-size: 0.375rem;
}
@mixin f7px {
  font-size: 0.4375rem;
}
@mixin f8px {
  font-size: 0.5rem;
}
@mixin f9px {
  font-size: 0.5625rem;
}
@mixin f10px {
  font-size: 0.625rem;
}
@mixin f11px {
  font-size: 0.6875rem;
}
@mixin f12px {
  font-size: 0.75rem;
}
@mixin f13px {
  font-size: 0.8125rem;
}
@mixin f14px {
  font-size: 0.875rem;
}
@mixin f15px {
  font-size: 0.9375rem;
}
@mixin f16px {
  font-size: 1rem;
}
@mixin f17px {
  font-size: 1.0625rem;
}
@mixin f18px {
  font-size: 1.125rem;
}
@mixin f19px {
  font-size: 1.1875rem;
}
@mixin f20px {
  font-size: 1.25rem;
}
@mixin f21px {
  font-size: 1.3125rem;
}
@mixin f22px {
  font-size: 1.375rem;
}
@mixin f23px {
  font-size: 1.4375rem;
}
@mixin f24px {
  font-size: 1.5rem;
}
@mixin f25px {
  font-size: 1.5625rem;
}
@mixin f26px {
  font-size: 1.625rem;
}
@mixin f27px {
  font-size: 1.6875rem;
}
@mixin f28px {
  font-size: 1.75rem;
}
@mixin f29px {
  font-size: 1.8125rem;
}
@mixin f30px {
  font-size: 1.875rem;
}
@mixin f31px {
  font-size: 1.9375rem;
}
@mixin f32px {
  font-size: 2rem;
}
@mixin f33px {
  font-size: 2.0625rem;
}
@mixin f34px {
  font-size: 2.125rem;
}
@mixin f35px {
  font-size: 2.1875rem;
}
@mixin f36px {
  font-size: 2.25rem;
}
@mixin f37px {
  font-size: 2.3125rem;
}
@mixin f38px {
  font-size: 2.375rem;
}
@mixin f39px {
  font-size: 2.4375rem;
}
@mixin f40px {
  font-size: 2.5rem;
}
@mixin f41px {
  font-size: 2.5625rem;
}
@mixin f42px {
  font-size: 2.625rem;
}
@mixin f43px {
  font-size: 2.6875rem;
}
@mixin f44px {
  font-size: 2.75rem;
}
@mixin f45px {
  font-size: 2.8125rem;
}
@mixin f46px {
  font-size: 2.875rem;
}
@mixin f47px {
  font-size: 2.9375rem;
}
@mixin f48px {
  font-size: 3rem;
}
@mixin f49px {
  font-size: 3.0625rem;
}
@mixin f50px {
  font-size: 3.125rem;
}
@mixin f51px {
  font-size: 3.1875rem;
}
@mixin f52px {
  font-size: 3.25rem;
}
@mixin f53px {
  font-size: 3.3125rem;
}
@mixin f54px {
  font-size: 3.375rem;
}
@mixin f55px {
  font-size: 3.4375rem;
}
@mixin f56px {
  font-size: 3.5rem;
}
@mixin f57px {
  font-size: 3.5625rem;
}
@mixin f58px {
  font-size: 3.625rem;
}
@mixin f59px {
  font-size: 3.6875rem;
}
@mixin f60px {
  font-size: 3.75rem;
}
@mixin f61px {
  font-size: 3.8125rem;
}
@mixin f62px {
  font-size: 3.875rem;
}
@mixin f63px {
  font-size: 3.9375rem;
}
@mixin f64px {
  font-size: 4rem;
}
@mixin f65px {
  font-size: 4.0625rem;
}
@mixin f66px {
  font-size: 4.125rem;
}
@mixin f67px {
  font-size: 4.1875rem;
}
@mixin f68px {
  font-size: 4.25rem;
}
@mixin f69px {
  font-size: 4.3125rem;
}
@mixin f70px {
  font-size: 4.375rem;
}
@mixin f71px {
  font-size: 4.4375rem;
}
@mixin f72px {
  font-size: 4.5rem;
}
@mixin f73px {
  font-size: 4.5625rem;
}
@mixin f74px {
  font-size: 4.625rem;
}
@mixin f75px {
  font-size: 4.6875rem;
}
@mixin f76px {
  font-size: 4.75rem;
}
@mixin f77px {
  font-size: 4.8125rem;
}
@mixin f78px {
  font-size: 4.875rem;
}
@mixin f79px {
  font-size: 4.9375rem;
}
@mixin f80px {
  font-size: 5rem;
}
@mixin f81px {
  font-size: 5.0625rem;
}
@mixin f82px {
  font-size: 5.125rem;
}
@mixin f83px {
  font-size: 5.1875rem;
}
@mixin f84px {
  font-size: 5.25rem;
}
@mixin f85px {
  font-size: 5.3125rem;
}
@mixin f86px {
  font-size: 5.375rem;
}
@mixin f87px {
  font-size: 5.4375rem;
}
@mixin f88px {
  font-size: 5.5rem;
}
@mixin f89px {
  font-size: 5.5625rem;
}
@mixin f90px {
  font-size: 5.625rem;
}
@mixin f91px {
  font-size: 5.6875rem;
}
@mixin f92px {
  font-size: 5.75rem;
}
@mixin f93px {
  font-size: 5.8125rem;
}
@mixin f94px {
  font-size: 5.875rem;
}
@mixin f95px {
  font-size: 5.9375rem;
}
@mixin f96px {
  font-size: 6rem;
}
@mixin f97px {
  font-size: 6.0625rem;
}
@mixin f98px {
  font-size: 6.125rem;
}
@mixin f99px {
  font-size: 6.1875rem;
}
@mixin f100px {
  font-size: 6.25rem;
}
.f6px {
  @include f6px;
}
.f8px {
  @include f8px;
}
.f12px {
  @include f12px;
}
.f14px {
  @include f14px;
}
.f16px {
  @include f16px;
}
.f18px {
  @include f18px;
}
.f20px {
  @include f20px;
}
.f24px {
  @include f24px;
}
.f32px {
  @include f32px;
}
.f48px {
  @include f48px;
}

// Labels

.label-hint {
  @extend .spp-regular;
  @extend .f8px;
  line-height: normal;
}

.label-sm {
  @extend .spp-medium;
  @extend .f12px;
  line-height: normal;
}

.label-md {
  @extend .spp-medium;
  @extend .f14px;
  line-height: normal;
}

.label-lg {
  @extend .spp-medium;
  @extend .f16px;
  line-height: normal;
}

// Titles

.title-xs {
  @extend .spp-bold;
  @extend .f16px;
  line-height: normal;
}

.title-sm {
  @extend .spp-bold;
  @extend .f20px;
  line-height: normal;
}

.title-md {
  @extend .spp-bold;
  @extend .f24px;
  line-height: normal;
}

.title-lg {
  @extend .spp-bold;
  @extend .f32px;
  line-height: normal;
}
