@use 'font-family';

// If you want to override variables do it here
@use 'variables' as v;

// Import Font
@import 'font';

// bootstrap
@import 'bootstrap/dist/css/bootstrap.min.css';

// Import toast style
@import 'ngx-toastr/toastr';

// If you want to add something do it here - must be below all other imported styles
@import 'custom';
@import 'stylesheet';
@import 'material';
@import 'toastr';
@import 'margins';
@import 'padding';
@import 'width';
@import 'reactive-form';
@import 'reactive-table';
@import 'buttons';

// Required for loading in body div
.main {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

//noinspection ALL
app-dashboard {
  display: flex;
  flex-direction: column;
  min-height: -webkit-fill-available;
}

.app-body {
  background-color: v.$background-color;
}

//noinspection ALL
.app-body .main {
  flex: 1;
  min-width: 0;
  min-height: -webkit-fill-available;
}

body {
  -ms-overflow-style: none;
  overflow-x: hidden;
  background-color: v.$background-color;
}

hr {
  border-top: 1px solid v.$pure-grey-neutral;
}

.main-content {
  padding-left: v.$desktop-main-content-padding;
  padding-right: v.$desktop-main-content-padding;
  padding-bottom: 4rem;
}

@media (max-width: v.$mobile-max-width) {
  .main-content {
    padding-left: v.$mobile-main-content-padding;
    padding-right: v.$mobile-main-content-padding;
    overflow-x: hidden;
  }
}

.no-margin {
  margin: 0 !important;
}

.auto-margin {
  margin: auto;
}

// padding and margins
.no-padding {
  padding: 0;
}

.pt-8px {
  padding-top: 0.5rem;
}

.pb-8px {
  padding-bottom: 0.5rem;
}

.mt-none {
  margin-top: 0;
}

.mt-8px {
  margin-top: 0.5rem;
}

.mt-10px {
  margin-top: 0.625rem;
}

.mt-14px {
  margin-top: 0.875rem;
}

.mt-16px {
  margin-top: 1rem;
}

.mt-20px {
  margin-top: 1.25rem;
}

.mt-24px {
  margin-top: 1.5rem;
}

.mt-28px {
  margin-top: 1.75rem;
}

.mt-48px {
  margin-top: 3rem;
}

.mb-none {
  margin-bottom: 0;
}

.mb-16px {
  margin-bottom: 1rem;
}

.mb-20px {
  margin-bottom: 1.25rem;
}

.mb-24px {
  margin-bottom: 1.5rem;
}

.mb-32px {
  margin-bottom: 2rem;
}

.lh-80p {
  line-height: 80%;
}

// Flexing
.flex-container {
  display: flex;
}

.flex-inline {
  display: inline-flex;
}

.flex-wrap-content {
  flex-wrap: wrap;
}

.flex-content-end {
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

// Align
.align-self-center {
  align-self: center;
}

// Justify

.justify-center {
  justify-content: center;
}

.justify-flex-end {
  justify-content: flex-end;
}

// Float
.float-left {
  float: left;
}

.float-right {
  float: right;
}

.no-padding {
  padding: 0;
}

.pt-8px {
  padding-top: 0.5rem;
}

.pt-16px {
  padding-top: 1rem;
}

.pt-24px {
  padding-top: 1.5rem;
}

.pb-8px {
  padding-bottom: 0.5rem;
}

.mt-none {
  margin-top: 0;
}

.mt-2px {
  margin-top: 0.125rem;
}

.mt-4px {
  margin-top: 0.25rem;
}

.mt-6px {
  margin-top: 0.375rem;
}

.mt-8px {
  margin-top: 0.5rem;
}

.mt-10px {
  margin-top: 0.625rem;
}

.mt-12px {
  margin-top: 0.75rem;
}

.mt-14px {
  margin-top: 0.875rem;
}

.mt-16px {
  margin-top: 1rem;
}

.mt-20px {
  margin-top: 1.25rem;
}

.mt-24px {
  margin-top: 1.5rem;
}

.mt-28px {
  margin-top: 1.75rem;
}

.mt-32px {
  margin-top: 2rem;
}

.mt-40px {
  margin-top: 2.5rem;
}

.mt-48px {
  margin-top: 3rem;
}

.ml-8px {
  margin-left: 0.5rem;
}

.ml-12px {
  margin-left: 0.75rem;
}

.ml-16px {
  margin-left: 1rem;
}

.ml-16px-force {
  margin-left: 1rem !important;
}

.mr-6px {
  margin-right: 0.375rem;
}

.mr-10px {
  margin-right: 0.625rem;
}

.mb-16px {
  margin-bottom: 1rem;
}

.mb-20px {
  margin-bottom: 1.25rem;
}

.mb-24px {
  margin-bottom: 1.5rem;
}

.mb-32px {
  margin-bottom: 2rem;
}

.mb-64px {
  margin-bottom: 4rem;
}

.mb-96px {
  margin-bottom: 6rem;
}

.lh-80p {
  line-height: 80%;
}

// Flexing
.flex-container {
  display: flex;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-inline {
  display: inline-flex;
}

.flex-wrap-content {
  flex-wrap: wrap;
}

.flex-content-end {
  justify-content: flex-end;
}

.flex-align-end {
  align-items: flex-end;
}

.flex-justify-center {
  justify-content: center;
}

.flex-align-center {
  align-items: center;
}

.flex-fill {
  flex: 1;
}

.full-centered-text {
  text-align: center;
  width: 100%;
}

// Align
.align-self-flex-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

// Sizing
.h100p {
  height: 100%;
}

.w100p {
  width: 100%;
}

// Block
.inline-block {
  display: inline-block;
}

.block {
  display: block;
}

.modal-content {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  border-radius: 10px !important;
  border: unset;
  overflow: visible;
}

.modal-md {
  max-width: 40.625rem;
}

@media (max-width: v.$mobile-max-width) {
  .modal-content {
    -webkit-border-radius: 10px 10px 0 0 !important;
    -moz-border-radius: 10px 10px 0 0 !important;
    border-radius: 10px 10px 0 0 !important;
    position: absolute;
    bottom: 0;
  }

  .modal-dialog {
    margin: 0;
    height: 100%;
    max-width: 100%;
    width: 100%;
  }
  .modal.fade:not(.show) .modal-dialog {
    transform: none;
  }
}

.blurred-background {
  backdrop-filter: blur(2px);
}

// Text wrapping

.ellipsis-nowrap-text {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.live-view-modal-landscape .modal-xl {
  max-width: 95vw;
  max-height: 90vh;
}

.live-view-modal-portrait .modal-xl {
  max-width: 65vw;
  max-height: 95vh;
}

// tooltip styling needs to be in the global stylesheet
.tooltip {
  font-size: 0.9rem;
  z-index: 1052 !important;
}

.show.tooltip {
  opacity: 1;
  z-index: 1052 !important;
}

.cdk-overlay-container {
  z-index: 1052;
}

.tooltip.tooltip-top,
.tooltip.tooltip-bottom,
.tooltip.tooltip-left,
.tooltip.tooltip-right {
  z-index: 100000;
}

// Animating

.animating {
  animation: 0.75s flicker-white-color infinite alternate;
}

.dark-animation.animating {
  animation: 0.75s flicker-dark-color infinite alternate;
}

@keyframes flicker-white-color {
  0% {
    background-color: rgba(255, 255, 255, 0.1);
  }

  10% {
    background-color: rgba(255, 255, 255, 0.2);
  }

  20% {
    background-color: rgba(255, 255, 255, 0.3);
  }

  30% {
    background-color: rgba(255, 255, 255, 0.4);
  }

  40% {
    background-color: rgba(255, 255, 255, 0.5);
  }

  50% {
    background-color: rgba(255, 255, 255, 0.6);
  }

  60% {
    background-color: rgba(255, 255, 255, 0.5);
  }

  70% {
    background-color: rgba(255, 255, 255, 0.4);
  }

  80% {
    background-color: rgba(255, 255, 255, 0.3);
  }

  90% {
    background-color: rgba(255, 255, 255, 0.2);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@keyframes flicker-dark-color {
  0% {
    background-color: rgba(200, 200, 200, 0.1);
  }

  10% {
    background-color: rgba(200, 200, 200, 0.2);
  }

  20% {
    background-color: rgba(200, 200, 200, 0.3);
  }

  30% {
    background-color: rgba(200, 200, 200, 0.4);
  }

  40% {
    background-color: rgba(200, 200, 200, 0.5);
  }

  50% {
    background-color: rgba(200, 200, 200, 0.6);
  }

  60% {
    background-color: rgba(200, 200, 200, 0.5);
  }

  70% {
    background-color: rgba(200, 200, 200, 0.4);
  }

  80% {
    background-color: rgba(200, 200, 200, 0.3);
  }

  90% {
    background-color: rgba(200, 200, 200, 0.2);
  }

  100% {
    background-color: rgba(200, 200, 200, 0.1);
  }
}
