// Variable overrides

// Global Constants
$navbar-height: 5.63rem;
$desktop-main-content-padding: 7rem;
$mobile-main-content-padding: 1rem;
$mobile-max-width: 768px;

// Default Colors
$background-color: #fffcf8;
$hover-green-color: #0d5d4b;
$hover-purple-color: #5e0f5e;
$light-blue-color: #4d5e72;
$dark-blue-color: #17293e;
$blue-hover: #001b24;
$white-color: #ffffff;
$off-white-color: #f8f8f8;
$extra-light-grey-color: #e8e8e8;
$mid-grey-color: #a0a0a0;
$dark-grey-color: #4e4e4e;
$hover-gray-color: #dadbde;
$light-grey-color: #c9c9c9;
$extra-dark-gray-color: #737680;
$faint-grey: #eeeeee;
$cool-grey: #9ca3af;
$light-red-color: #fa555533;
$red-color: #e11f27;
$red-highlight: #fff0f0;
$off-red-color: #984545;
$black-color: #000000;
$turquoise-color: #b1e7e2;
$ocean-color: #00504b;
$salmon-color: #f28872;
$active-color: #31bf5d;
$inactive-color: #737680;
$green-color: #63d58f;
$yellow-color: #ffd74b;
$yellow-color-80: #ffd74bcc;
$negative-color: #e56659;
$negative-highlight: #fcebea;
$overlay-color: #3b3b3c;
$pill-signed: #fafafa;

$primary-green-color: #13856b;
$primary-blue-color: #104255;
$primary-blue-hover-color: #216985;
$primary-red-color: #dc3725;
$primary-yellow-color: #e09f2f;
$primary-purple-color: #981d97;
$purple-default-color: #7b167a;
$purple-hover: #410741;
$bright-pink-color: #981d97;
$primary-beige-color: #e6d7b8;
$primary-neutral-color: #a5b0ae;
$secondary-neutral-color: #cacfd5;
$pure-grey-neutral: #afafaf;
$pure-grey-neutral-50: #f7f7f7;
$pure-grey-neutral-100: #f2f2f2;
$pure-grey-neutral-200: #dedede;
$pure-grey-neutral-300: #cacaca;
$pure-grey-neutral-600: #666666;
$pure-grey-neutral-700: #5e5e5e;
$cool-neutral-100: #f3f4f5;
$cool-neutral-200: #e5e7eb;
$cool-neutral-300: #d2d5db;
$cool-neutral-400: #9ca3af;
$cool-neutral-500: #6b7280;
$cool-neutral-600: #4c5563;
$cool-neutral-700: #384152;
$cool-neutral-800: #202937;
$dropdown-link-hover: #f8f9fa;
$green-fill-color: #587f00;
$pale-primary-green-color: #aac8c1;
$banner-text-green: #395200;
$banner-warn: #fafadc;
$banner-text-red: #9d0209;
$red-fill-default: #7b0005;
$red-fill-hover: #bf0f16;
$green-text-title: #192600;
$green-600: #78ab00;
$green-disabled: #d4efa3;
$purple-600: #6b1d6b;
$red-500: #c63221;
$red-50: #ffeaeb;
$green-400: #13856b;
$green-40: #e8f3f1;
$text-hint-primary-color: #929292;
$pending-item-color: #d99025;
$orange-50: #fcf6eb;
$profile-badge-background-color: #fdfaf4;
$profile-badge-border-color: #ebe2c3;
$avatar-fill: #f1ebd7;
$completed-fill: #e8f7d9;
$green-title-color: #192600;

$primary-color: $primary-blue-color;
$secondary-color: $primary-purple-color;
$primary-text-color: $black-color;
$grey-text-color: $pure-grey-neutral;
$light-text-color: $white-color;
$error-text-color: #9d0209;
$error-border-color: #e11f27;
$transparent-color: transparent;
$primary-white-color: $white-color;

// Nav Items
$nav-background-color: $primary-white-color;
$nav-hover-color: $cool-neutral-700;
$nav-active-text-color: $primary-purple-color;
$nav-active-background-color: $transparent-color;
$nav-inactive-text-color: $cool-neutral-500;
$nav-notification-pill-color: $red-color;
$nav-bottom-border-color: $cool-neutral-200;
$nav-active-hover-color: #5e0f5e;

// Button Items
$button-white-text-color: $white-color;
$button-black-text-color: $black-color;
$button-preferred-hover-color: $primary-blue-hover-color;
$button-neutral-color: $white-color;
$button-neutral-hover-color: $hover-gray-color;
$button-dark-neutral-color: $dark-grey-color;
$button-dark-neutral-hover-color: $extra-dark-gray-color;
$button-outlined-hover-color: $pure-grey-neutral;
$button-border-color: $hover-gray-color;
$button-text-color: $white-color;
$button-text-hover-color: $off-white-color;

// Pill Items
$pill-active-background-color: $primary-blue-color;
$pill-active-text-color: $white-color;
$pill-background-color: transparent;
$pill-border-color: $cool-grey;
$pill-text-color: $cool-neutral-600;

// Label Items
$label-positive-color: $turquoise-color;
$label-positive-text-color: $ocean-color;
$label-neutral-color: $hover-gray-color;
$label-neutral-text-color: $black-color;
$label-negative-color: $salmon-color;
$label-negative-text-color: $white-color;

// Datatable
$datatable-overdue-row-color: #fcebea;
$datatable-overdue-border-color: #ed968d;

// Tabs
$material: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: $primary-color,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $primary-text-color,
    100: $primary-text-color,
    200: $primary-text-color,
    300: $primary-text-color,
    400: $primary-text-color,
    500: $light-text-color,
    600: $light-text-color,
    700: $light-text-color,
    800: $light-text-color,
    900: $light-text-color,
    A100: $primary-text-color,
    A200: $light-text-color,
    A400: $light-text-color,
    A700: $light-text-color
  )
);

// Drop Down
$dropdown-base-color: $pure-grey-neutral;
$dropdown-hover-color: $white-color;
$dropdown-text-color: $black-color;
$dropdown-border-color: $black-color;
$dropdown-hover-color: $white-color;
$dropdown-hover-border-color: $black-color;
$dropdown-hover-text-color: $black-color;

// Pills
$pill-text-color: $cool-neutral-600;
$pill-text-active-color: $white-color;
$pill-default-color: $pure-grey-neutral;
$pill-hover-color: $hover-gray-color;
$pill-active-color: $primary-blue-color;

// Checkboxes
$checkbox-base-color: $pure-grey-neutral;
$checkbox-hover-color: $hover-gray-color;
$checkbox-checkmark-color: $white-color;
$checkbox-checked-color: $primary-purple-color;
$checkbox-empty-color: #d2d5db;

// Switches
$switch-base-color: $cool-neutral-300;
$switch-hover-color: $hover-gray-color;
$switch-activated-color: $green-600;

// Radio
$radio-base-color: $pure-grey-neutral;
$radio-hover-color: $hover-gray-color;
$radio-activated-color: $primary-color;

// Toggle
$toggle-base-color: $pure-grey-neutral;
$toggle-hover-color: $hover-gray-color;
$toggle-activated-color: $primary-color;

// Media Upload
$media-upload-border: $hover-gray-color;
$media-upload-background: $off-white-color;
$media-upload-blue-color: #388efc;
$media-upload-grey-color: $extra-dark-gray-color;
$media-name-color: #a2a2a2;
$media-size-color: #a2a2a2;
$media-error-color: $red-color;

// Lists
$list-background-color: #fdfdfd;
$list-border-color: #707070;
$list-hover-color: $off-white-color;
$list-active-color: #deecff;

// Cards
$card-base-color: #9fa2ad;
$card-banner-color: $pure-grey-neutral;
$card-hover-color: $hover-gray-color;
$card-title-color: $extra-dark-gray-color;
$card-text-color: $black-color;

// Loading
$progress-bar-background-color: $off-white-color;
$progress-bar-progress-color: $primary-blue-color;
$progress-bar-error-color: $red-color;
$progress-bar-success-color: $green-color;

// Textfield
$textfield-fill-color: $pure-grey-neutral;
$textfield-focus-border-color: $black-color;
$textfield-focus-fill-color: $white-color;
$textfield-error-red-color: $red-color;

// Datatable
$datatable-row-even-background: $off-white-color;
$datatable-row-odd-background: $white-color;
$datatable-hover-background: $faint-grey;
$datatable-nested-row-background: $extra-light-grey-color;
$datatable-nested-row-border: $primary-blue-color;
$datatable-primary-text: $primary-text-color;
$datatable-header-text: $mid-grey-color;
$datatable-footer-text: $extra-dark-gray-color;
$datatable-pagination-grey: $pure-grey-neutral;
$datatable-pagination-hover: $hover-gray-color;
$datatable-pagination-selected-color: $primary-blue-color;
$datatable-pagination-active-text: $white-color;
$datatable-pagination-disabled: $off-white-color;

// Scrollbar
$scrollbar-bg-color: $white-color;
$scrollbar-color: $light-blue-color;

// Variable overrides
$card-border-radius: 0.75rem;
$table-border-color: $faint-grey;

// System Banners

$system-banner-error-bg: #ffeaeb;
$system-banner-error-color: #9d0209;
$system-banner-info-bg: #dcebfa;
$system-banner-info-color: #104255;
$system-banner-success-bg: #e8f7d9;
$system-banner-success-color: #395200;
$system-banner-warning-bg: #fafadc;
$system-banner-warning-color: #4c5563;
