@import 'variables';

:root {
  // all
  --button-font-family: 'Metropolis';
  --button-font-weight: 600;
  --button-font-style: normal;
  // info text
  --button-info-font-family: 'Metropolis';
  --button-info-font-weight: 600;
  --button-info-font-style: normal;
  --button-info-font-size: 1rem;
  // defaults
  --button-default-text-color: #{$button-text-color};
  --button-default-background-color: #{$black-color};
  --button-default-hover-color: #{$button-text-hover-color};
  --button-default-padding: 1rem 1.5rem;
  --button-default-border-radius: 1.875rem;
  --button-default-height: 3.375rem;
  --button-default-font-size: 1rem;
  // button rounded
  --button-rounded-text-color: #{$white-color};
  --button-rounded-background-color: #{$dark-grey-color};
  --button-rounded-hover-color: #{$extra-dark-gray-color};
  --button-rounded-disabled-background-color: #{$pure-grey-neutral};
  // clear
  --button-clear-text-color: #{$black-color};
  --button-clear-font-family: 'Metropolis';
  --button-clear-font-weight: 500;
  --button-clear-font-style: normal;
  // destructive
  --button-destructive-text-color: #{$red-color};
  --button-destructive-background-color: #{$white-color};
  --button-destructive-disabled-background-color: #{$red-color};
  // neutral
  --button-neutral-background-color: #{$button-neutral-color};
  --button-neutral-text-color: #{$primary-color};
  --button-neutral-hover-background-color: #{$button-neutral-hover-color};
  --button-neutral-disabled-background-color: #{$button-neutral-color};
  // outlined
  --button-outlined-background-color: #{$white-color};
  --button-outlined-text-color: #{$primary-color};
  --button-outlined-hover-background-color: #{$button-outlined-hover-color};
  // primary
  --button-primary-text-color: #{$white-color};
  --button-primary-background-color: #{$primary-color};
  --button-primary-hover-color: #{$primary-blue-hover-color};
  --button-primary-disabled-background-color: #104255;
  // text
  --button-text-color: #{$button-black-text-color};
  --button-text-background-color: #{$button-text-color};
  --button-text-hover-color: #{$button-text-hover-color};
  // inline text
  --button-inline-text-color: #{$button-black-text-color};
  --button-inline-text-font-weight: 600;
  --button-inline-text-background-color: $transparent-color;
  --button-inline-text-hover-color: $transparent-color;

  .button-neutral {
    border: 1px solid #{$pure-grey-neutral-300};
  }
}
