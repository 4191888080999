@use 'src/scss/variables' as v;
@use 'src/scss/font' as f;
@import 'src/scss/custom';

// Nav Bar

.app-body .spp-navbar-show ~ .main {
  padding-top: v.$navbar-height !important;
}

.app-body .spp-navbar-hide ~ .main {
  padding-top: 0 !important;
}

/* Text_Field_Start ----------------------------------------------- */
//noinspection ALL
.custom-textfield {
  color: v.$primary-text-color;
  height: 2.5rem;
  width: -webkit-fill-available;
  border-radius: 0.625rem;
  border: 0;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: left;
  background-color: v.$textfield-fill-color;
  border: 1px solid v.$textfield-fill-color;
  padding: 0.8125rem 0.5rem;
  margin-bottom: 1.5rem;
  @extend .spp-regular;
}

.custom-textfield:read-only {
  outline: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: v.$pure-grey-neutral;
}

.custom-textfield:read-only:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: v.$pure-grey-neutral;
}

.custom-textfield.has-error {
  border: 1px solid v.$textfield-error-red-color;
}

.custom-textfield:focus {
  color: v.$primary-text-color;
  outline: none;
  border: 1px solid v.$textfield-focus-border-color;
  background-color: v.$textfield-focus-fill-color;
  box-shadow: 0 0 1px 1px v.$extra-light-grey-color;
  @extend .spp-medium;
}

.custom-textfield.chip-input.has-error {
  border-top: 1px solid v.$textfield-error-red-color;
  border-right: 1px solid v.$textfield-error-red-color;
  border-bottom: 1px solid v.$textfield-error-red-color;
}

.custom-textfield.chip-input {
  border: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
  padding-left: 0;
}

/* ------------------------------------------------- Text_Field_End */

// I named things according to how they were labelled in xd.adobe by Conor
/* Text_Start ----------------------------------------------- */
.custom-headline {
  @extend .spp-bold;
  @extend .zero-letter-spacing;
  font-size: 2rem;
  color: v.$black-color;
  text-align: left;
  opacity: 1;
}

.custom-section-title {
  @extend .spp-bold;
  @extend .zero-letter-spacing;
  font-size: 1.5rem;
  color: v.$black-color;
  text-align: left;
  opacity: 1;
}

.custom-section-subtitle {
  @extend .spp-bold;
  @extend .zero-letter-spacing;
  color: v.$black-color;
  font-size: 1rem;
  text-align: left;
  opacity: 1;
}

.custom-section-subtext {
  @extend .spp-regular;
  @extend .zero-letter-spacing;
  color: v.$extra-dark-gray-color;
  font-size: 1rem;
  line-height: 1.125rem;
  text-align: left;
  opacity: 1;
}

.custom-modal-section-header-text {
  @extend .spp-bold;
  @extend .zero-letter-spacing;
  color: v.$black-color;
  font-size: 1rem;
  text-align: left;
  opacity: 1;
}

.custom-error-text {
  @extend .spp-italic;
  @extend .zero-letter-spacing;
  color: v.$red-color;
  font-size: 0.625rem;
  text-align: left;
  opacity: 1;
}

h3 {
  @extend .spp-bold;
  @extend .zero-letter-spacing;
  color: v.$black-color;
  text-align: left;
  font-size: 1.125rem;
  opacity: 1;
}

body {
  @extend .spp-regular;
  @extend .zero-letter-spacing;
  color: v.$black-color;
  text-align: left;
  font-size: 0.875rem;
  opacity: 1;
  margin: 0;
}

/* ------------------------------------------------- Text_End */

/* Buttons_Start -------------------------------------------- */
.custom-button {
  display: flex;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.625rem;
  line-height: normal;
  opacity: 1;
  font-size: 0.875rem;
  text-align: center;
  vertical-align: middle;
  transition: background 0.8s;
  cursor: pointer;
  @extend .zero-letter-spacing;
  @extend .spp-semi-bold;
  @extend .ripple;
}

.custom-button img {
  margin-right: 0.5rem;
  height: 1.125rem;
}

.custom-button:focus {
  outline: none;
}

.custom-button .show-on-hover {
  display: none;
}

.custom-button:hover .hide-on-hover {
  display: none;
}

.custom-button:hover .show-on-hover {
  display: inline-block;
}

.custom-button:active {
  background-color: v.$light-grey-color;
  background-size: 100%;
  transition: background 0s;
}

.preferred-button {
  background-color: v.$primary-color;
  color: v.$button-white-text-color;
}

.icon-button-icon {
  margin-right: 0.625rem;
  height: 100%;
}

.rounded-icon-button {
  height: 2rem;
  width: 2rem;
  padding: 0.4rem;
  border-radius: 50%;
}

.rounded-icon-button .icon-button-icon {
  margin: 0;
}

.preferred-button:hover {
  background-color: v.$button-preferred-hover-color;
}

.preferred-button.disabled {
  background-color: v.$light-grey-color;
}

.neutral-button {
  background-color: v.$button-neutral-color;
  color: v.$button-black-text-color;
}

.neutral-button:hover {
  background-color: v.$button-neutral-hover-color;
}

.neutral-button.disabled {
  background-color: v.$pure-grey-neutral;
  opacity: 0.5;
}

.text-link-button {
  background-color: transparent;
  @extend .text-link;
}

.text-link-button:hover {
  background-color: transparent;
}

.text-link-button.disabled {
  background-color: transparent;
  opacity: 0.5;
}

.dark-neutral-button {
  background-color: v.$button-dark-neutral-color;
  color: v.$button-white-text-color;
}

.dark-neutral-button:hover {
  background-color: v.$button-dark-neutral-hover-color;
}

.dark-neutral-button.disabled {
  background-color: v.$pure-grey-neutral;
}

.outlined-button {
  background-color: v.$white-color;
  color: v.$button-black-text-color;
  border-color: v.$pure-grey-neutral-300;
  @extend .bordered-button;
}

.outlined-button:hover {
  background-color: v.$button-outlined-hover-color;
}

.outlined-button.disabled {
  background-color: v.$light-grey-color;
  border: none;
}

.destructive-button {
  background-color: v.$white-color;
  color: v.$red-color;
  border: 0.124rem solid v.$red-color;
}

.destructive-button:hover {
  background-color: v.$red-color;
  color: v.$button-white-text-color;
}

.destructive-button.disabled {
  background-color: v.$light-grey-color;
  color: v.$button-black-text-color;
  border: none;
}

.destructive-button.hide-button {
  display: none;
}

.text-button {
  background-color: v.$button-text-color;
  color: v.$primary-color;
}

.text-button:hover {
  background-color: v.$button-text-hover-color;
}

.back-button {
  padding: 0.75rem 1rem 0.75rem 0;
  opacity: 0.5;
}

.back-button:hover {
  opacity: 1;
  background-color: v.$button-text-color;
}

.text-link-helper {
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: left;
  color: v.$primary-color;
  @extend .spp-medium;
}

.inline-text-link {
  display: inline-block;
  margin: 0 0.5rem;
}

.text-link {
  color: v.$primary-color;
}

.text-link.secondary {
  color: v.$mid-grey-color;
}

.text-link.disabled {
  color: v.$light-grey-color;
}

.text-link.disabled:hover {
  text-decoration: none;
  cursor: not-allowed;
}

.text-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.text-link.destructive-link {
  color: v.$red-color;
}

.text-link.destructive-link.disabled {
  color: v.$light-red-color;
}

.bordered-button {
  border: 0.124rem solid v.$button-border-color;
}

.full-width-button {
  width: 100%;
}

.in-button-loading {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 1.5rem;
  height: 100%;
}

.in-button-loading app-loading .loading-container {
  background-color: transparent !important;
}

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.2s, opacity 0.5s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.2;
    transition: 0s;
  }
}

/* ---------------------------------------------- Buttons_End */

/* Labels_Start -------------------------------------------- */
.custom-label {
  padding: 0.125rem 0.4rem;
  border: none;
  border-radius: 0.3rem;
  line-height: 1.125rem;
  opacity: 1;
  height: 1.25rem;
  font-size: 0.625rem;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  @extend .spp-semi-bold;
}

.preferred-label {
  background-color: v.$primary-color;
  color: v.$white-color;
}

.positive-label {
  background-color: v.$label-positive-color;
  color: v.$label-positive-text-color;
}

.neutral-label {
  background-color: v.$label-neutral-color;
  color: v.$label-neutral-text-color;
}

.negative-label {
  background-color: v.$label-negative-color;
  color: v.$label-negative-text-color;
}

.overflow-wrap-break-word {
  overflow-wrap: break-word;
}

.paddingless-text-button {
  padding: 0;
  border: none;
  background-color: transparent;
  color: v.$primary-color;
  cursor: pointer;
  font-size: 0.875rem;
  @extend .spp-medium;

  &:hover {
    text-decoration: underline;
  }
}

.destructive-paddingless-text-button {
  color: v.$primary-red-color;
  @extend .spp-semi-bold;
  @extend .paddingless-text-button;
}

.paddingless-text-button.disabled {
  color: v.$text-hint-primary-color;
  cursor: not-allowed;
  text-decoration: none;
}

.primary-paddingless-text-button {
  color: v.$primary-blue-color;
  @extend .spp-semi-bold;
  @extend .paddingless-text-button;
}

.no-style-button {
  border: none;
  background: none;
  margin: 0;
  padding: 0;
}

/* ---------------------------------------------- Labels_End */

/* Toggle_Switch_Start ------------------------------------- */
.custom-switch-container {
  color: v.$primary-text-color;
  @extend .label-sm;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    height: 1rem;
    width: 1rem;
    margin-left: 1.5rem;
    opacity: 0.5;
  }

  label {
    margin-left: 1rem;
  }
}

/* The switch - the box around the slider */
label.custom-switch {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 2.875rem;
  height: 1.5rem;
  margin: 0;
}

/* Hide default HTML checkbox */
.custom-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.custom-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: v.$switch-base-color;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.custom-slider:hover {
  background-color: v.$switch-hover-color;
}

.custom-slider:before {
  position: absolute;
  content: '';
  height: 1.25rem;
  width: 1.25rem;
  top: 0.1rem;
  left: 0.1rem;
  background-color: v.$white-color;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.custom-slider.disabled:before {
  background-color: v.$faint-grey;
}

input:checked + .custom-slider {
  background-color: v.$switch-activated-color;
}

input:checked + .custom-slider.disabled {
  background-color: v.$light-grey-color;
}

input:focus + .custom-slider {
  outline: none;
}

input:checked + .custom-slider:before {
  -webkit-transform: translateX(1.375rem);
  -ms-transform: translateX(1.375rem);
  transform: translateX(1.375rem);
}

/* --------------------------------------- Toggle_Switch_End */

/* Radio_Start --------------------------------------------- */
.custom-radio-form {
  vertical-align: middle;
}

.custom-radio-label {
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: left;
  margin-left: 0.375rem;
  vertical-align: middle;
  margin-bottom: 0;
  @extend .spp-medium;
}

.custom-radio {
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: middle;
}

.custom-radio-option {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

.custom-radio-option-inline {
  display: inline-block;
  padding: 0 1rem;
}

.custom-radio-option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-custom-radio {
  display: inline-block;
  height: 1.25rem;
  width: 1.25rem;
  vertical-align: middle;
  background-color: v.$radio-base-color;
  border-radius: 50%;
  flex-shrink: 0;
}

.custom-radio-option.disabled input {
  cursor: not-allowed;
}

.custom-radio-option.disabled:hover .custom-custom-radio {
  background: v.$radio-base-color;
}

.custom-radio-option:hover .custom-custom-radio {
  background-color: v.$radio-hover-color;
}

.custom-radio-option input:checked ~ .custom-custom-radio {
  background-color: v.$radio-activated-color;
}

.custom-custom-radio:after {
  content: '';
  position: absolute;
  display: none;
}

.custom-radio-option input:checked ~ .custom-custom-radio:after {
  display: block;
}

.custom-radio-option .custom-custom-radio:after {
  position: relative;
  top: 0.4375rem;
  left: 0.4375rem;
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: v.$white-color;
}

/* ----------------------------------------------- Radio_End */

/* Toggle_Start -------------------------------------------- */
.custom-toggle {
  border-radius: 2px;
  background-color: v.$toggle-base-color;
  height: 2rem;
  width: 2rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1rem;
  padding: 0.5625rem 0;
  @extend .spp-medium;
}

.custom-toggle:hover {
  background-color: v.$toggle-hover-color;
}

.custom-toggle.active {
  background-color: v.$toggle-activated-color;
  color: v.$white-color;
}

/* ---------------------------------------------- Toggle_End */

/* Cards_Start --------------------------------------------- */

.card {
  overflow: hidden;
}

.card.custom-card {
  width: 14rem;
  height: 10rem;
  min-width: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1rem;
  opacity: 1;
  background-size: cover;
  background-position: top;
  cursor: pointer;
  @extend .custom-card-radius-top;
  @extend .custom-card-radius-bottom;
}

.card.custom-card:hover .custom-card-label {
  background-color: v.$hover-gray-color;
  opacity: 1;
  height: 5rem;
  transition: 0.25s ease-in-out;
}

.custom-card-label {
  height: 4.5rem;
  background: v.$card-banner-color 0 0 no-repeat padding-box;
  opacity: 0.9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: auto;
  @extend .custom-card-radius-bottom;
}

.custom-card-radius-top {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.custom-card-radius-bottom {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}

.custom-card-label-title {
  @extend .spp-medium;
  @extend .zero-letter-spacing;
  text-align: left;
  font-size: 0.875rem;
  color: #737680;
  opacity: 1;
}

.custom-card-label-text {
  @extend .spp-bold;
  @extend .zero-letter-spacing;
  font-size: 1.3rem;
  text-align: left;
  color: v.$card-text-color;
  opacity: 1;
}

.card-deck {
  margin: 1.5rem 0 0 0;
}

.custom-card-deck {
  margin: 1.5rem 0 0 0;
  overflow-y: hidden;
  max-height: 20rem;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 0.25rem;
}

.custom-card-deck .card {
  display: inline-flex;
}

card.custom-card.custom-card-with-bottom-margin {
  margin-bottom: 1rem;
}

/* ----------------------------------------------- Cards_End */

/* Media_Upload_Start -------------------------------------- */
.custom-upload-area {
  border: 1px dashed v.$media-upload-border;
  border-radius: 0.625rem;
  background-color: v.$media-upload-background;
  width: 100%;
}

.custom-upload-form {
  text-align: center;
}

.custom-upload-icon {
  height: 1.5rem;
  margin: 1.5rem auto auto;
  display: block;
}

.custom-upload-text {
  display: block;
  padding-top: 1rem;
  font-size: 0.875rem;
  line-height: 1rem;
  @extend .spp-medium;
}

.custom-upload-label {
  color: v.$media-upload-blue-color;
}

.custom-upload-label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.secondary-upload-text {
  padding-top: 0.5rem;
  color: v.$media-upload-grey-color;
  margin-bottom: 1.5rem;
}

.custom-file-input {
  display: none;
}

.custom-upload-list-container {
  width: 100%;
  padding: 1.25rem 0;
}

.custom-upload-list {
  list-style: none;
  padding: 0;
}

.custom-upload-button {
  display: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.custom-upload-button:focus {
  outline: none;
}

.custom-upload-button-icon {
  height: 1.25rem;
  background-color: transparent;
  filter: invert(49%) sepia(8%) saturate(428%) hue-rotate(189deg) brightness(92%) contrast(86%);
}

.custom-upload-preview.error .custom-remove-upload-button {
  display: none;
}

.custom-upload-preview.error .custom-refresh-upload-button {
  display: inline-block;
}

.custom-upload-preview.success .custom-remove-upload-button {
  display: inline-block;
}

.custom-upload-preview.success .custom-refresh-upload-button {
  display: none;
}

/* ---------------------------------------- Media_Upload_End */

/* Lists_Start --------------------------------------------- */
.custom-search-results {
  width: 100%;
  border-radius: 0.625rem;
  background-color: v.$list-background-color;
  border: 1px solid v.$list-border-color;
  margin: 0.625rem;
  max-height: 33vh;
}

.custom-results-container {
  overflow: scroll;
  max-height: 26vh;
  display: block;
}

.custom-search-results-header {
  padding: 1.5625rem 0 1rem 0;
  display: block;
  font-size: 1rem;
  line-height: 1.125rem;
  text-align: left;
  border-bottom: 1px solid v.$pure-grey-neutral;
  width: calc(100% - 4rem);
  margin: 0 2rem;
  @extend .spp-medium;
}

.custom-list {
  list-style: none;
  padding: 0 1rem 0.5rem 1rem;
  margin: 0;
}

.custom-list-item-image {
  height: 3rem;
  width: 3rem;
  margin: 0 0 0 0.75rem;
  border-radius: 0.25rem;
}

.custom-list-item-image-icon {
  height: 3rem;
  width: 3rem;
  margin: 0 0 0 0.75rem;
  border-radius: 0.25rem;
  background-color: v.$pure-grey-neutral;
  padding: 0.75rem;
}

.custom-list-item-text-container {
  padding: 0 1rem;
  display: inline-block;
  width: calc(100% - 4rem);
  vertical-align: middle;
}

.custom-list-item-text {
  font-size: 0.875rem;
  line-height: 1.125rem;
  display: flex;
  @extend .spp-bold;
}

.custom-list-item-subtext {
  font-size: 0.625rem;
  line-height: 0.75rem;
  display: flex;
  @extend .spp-medium;
}

.custom-list-item {
  padding: 0.75rem 0;
}

.custom-list-item:hover {
  background-color: v.$list-hover-color;
  cursor: pointer;
  border-radius: 0.25rem;
}

.custom-list-item.inactive:hover {
  background-color: v.$list-background-color;
}

.custom-list-item.active {
  background-color: v.$list-active-color;
  cursor: text;
}

.menu-list-container {
  max-height: 30rem;
  padding-top: 1rem;
  width: 100%;
  display: block;
  background: v.$white-color;
}

.menu-list-item {
  padding: 0.75rem 1rem;
  color: v.$primary-text-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  background: v.$white-color;
  z-index: 99;
  box-shadow: 0 0 2px 2px rgba(218, 220, 222, 0.7);
  border-radius: 0.625rem;
  margin: 1rem 0;
}

.menu-list-item.disabled {
  cursor: not-allowed;
  background-color: v.$extra-light-grey-color;
}

.menu-added {
  @extend .spp-semi-bold;
}

.list-row {
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.list-text {
  display: block;
}

.list-title {
  @extend .label-md;
  color: v.$primary-text-color;
}

.list-subtitle {
  @extend .label-sm;
  color: v.$grey-text-color;
}

/* ----------------------------------------------- Lists_End */

/* Image_Start --------------------------------------------- */
.custom-profile-image {
  display: inline-block;
  border-radius: 50%;
  height: 5.625rem; // 90px;
  width: 5.625rem; // 90px;
  background-color: v.$pure-grey-neutral;
  cursor: pointer;
}

.custom-profile-image-padding {
  padding: 1.75rem;
}

/* ----------------------------------------------- Image_End */

/* Modal_Start --------------------------------------------- */

ngb-modal-window {
  overflow: hidden !important;
}

.sticky-header-container {
  background-color: #ffffff;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  padding: 1.5rem 2rem;
  z-index: 1;
  border-bottom: 1px solid v.$pure-grey-neutral-200;
}

.sticky-body-container {
  overflow-y: scroll;
  padding: 0 1.5rem;
}

.sticky-footer-container {
  background: linear-gradient(
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 1),
    v.$white-color,
    v.$white-color
  );
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  padding: 1.5rem 2rem;
  z-index: 10;
  border-top: 1px solid v.$pure-grey-neutral-200;
}

.modal-footer {
  border-top: none;
}

.modal-form-negative-padding {
  margin: 0 -1.5rem;
}

.modal-body {
  max-height: calc(100vh - 3rem);
  overflow-y: visible;
  padding: 2rem 1.5rem 1.5rem;
}

.modal-body.sticky {
  padding: 0;
}

.modal-header {
  display: block;
  padding: 0;
  border-bottom: none;
  justify-content: space-between;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  letter-spacing: -0.025rem;
  color: v.$cool-neutral-800;
}

.modal-subtitle {
  display: block;
  font-size: 1.125rem;
  line-height: 2rem;
  color: v.$primary-text-color;
  @extend .spp-medium;
}

.modal-sub-subtitle {
  font-size: 0.875rem;
  text-align: left;
  color: v.$primary-text-color;
  @extend .spp-medium;
}

.modal-sub-subtitle-text {
  display: inline-block;
  width: 50%;
  color: v.$extra-dark-gray-color;
  @extend .spp-medium;
}

.modal-sub-subtitle-text span {
  display: inline-block;
  color: v.$primary-text-color;
  @extend .spp-semi-bold;
}

.inline-subtitle {
  display: inline-block;
  min-width: 50%;
}

.modal-subtitle-action {
  float: right;
  top: -0.5rem;
  position: relative;
  text-align: right;
}

.footer-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0 0 0;
  float: right;
}

.modal-footer-flex-end {
  display: flex;
  justify-content: flex-end;
}

.modal-divider {
  width: 100%;
  border-bottom: 1px solid v.$hover-gray-color;
  margin: 0.5rem 0;
  padding-top: 0.5rem;
}

/* ----------------------------------------------- Modal_End */

/* CSS Card Form Start --------------------------------------------- */
//noinspection ALL
.card-container-title {
  position: absolute;
  margin-top: v.$navbar-height;
  padding: 2rem 7rem 2rem 7rem;
  top: 0;
  left: 0;
  width: -webkit-fill-available;
  overflow: auto;
  background-color: v.$white-color;
  box-shadow: 0 3px 13px 0 rgba(129, 114, 75, 0.01), 0 3px 11px 0 rgba(129, 114, 75, 0.02),
    0 3px 8px 0 rgba(129, 114, 75, 0.03), 0 2px 4px 0 rgba(129, 114, 75, 0.04);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .header {
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
    letter-spacing: -0.035rem;
    color: v.$cool-neutral-700;
  }

  .sub-header {
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: v.$cool-neutral-600;
  }
}

.link-button {
  color: v.$primary-color;
  font-size: 1rem;
  font-weight: 600;
  margin: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.link-button:hover {
  text-decoration: underline;
}

.link-button-black {
  color: v.$black-color;
  @extend .link-button;
}

.card-container {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  padding: 1rem 0 4rem 0;

  .header {
    color: v.$cool-neutral-700;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.25rem;
    letter-spacing: -0.035rem;
    @extend .spp-semi-bold;
  }

  .subtext {
    font-size: 0.875rem;
    color: v.$pure-grey-neutral-600;
    @extend .spp-regular;
  }
}

.card-form-container {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-right: 2rem;
  gap: 2rem;

  .card-header {
    display: flex;
    font-weight: 600;
    font-size: 1.125rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    color: v.$cool-neutral-800;
  }

  .card-subtext {
    display: block;
    font-size: 1rem;
    color: v.$pure-grey-neutral-600;
    line-height: 1.5rem;
    @extend .spp-regular;
  }

  .card-section-header {
    display: flex;
    font-size: 1rem;
    line-height: 1.5rem;
    color: v.$cool-neutral-700;
    @extend .spp-semi-bold;
  }

  .card-subheader {
    @extend .card-header;
    font-size: 1rem;
    margin-bottom: 0;
  }
}

.card-form-content-container {
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.card-form-action-container {
  width: 30%;
  height: fit-content;
  display: flex;
  gap: 2rem;
  flex-direction: column;

  .action-title {
    font-size: 1.125rem;
    padding-bottom: 1rem;
    @extend .spp-semi-bold;
  }

  .expiring-title {
    font-size: 1rem;
    padding-bottom: 0.5rem;
    @extend .spp-semi-bold;
  }

  .card-header {
    display: flex;
    font-weight: 600;
    font-size: 1.125rem;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    color: v.$cool-neutral-800;
  }

  .card-subtext {
    font-size: 0.875rem;
    color: v.$pure-grey-neutral-600;
    @extend .spp-regular;
  }

  .action-items-container {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
  }

  .action-item {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    width: fit-content;
  }

  .action-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }
}

.option-container {
  display: flex;
  flex-direction: row;
}

.option-top {
  padding-bottom: 1rem;
}

.option-bottom {
  padding-top: 1rem;
}

.option-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 1.5rem;
}

.card-container-button-row {
  display: flex;
  gap: 1rem;
}

@media (max-width: v.$mobile-max-width) {
  .info-card {
    margin-bottom: 0;
  }

  .card-form-content-container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 3rem;
  }

  .card-container-title {
    padding: 2rem 1.5rem 1.5rem 1.5rem;

    .header {
      font-size: 1.5rem;
    }
  }

  .card-container {
    padding: 0 1rem 2rem 0.5rem;
    margin-right: 0;
  }

  .card-form-action-container {
    width: 100%;
    margin-bottom: 2rem;
  }

  .card-form-container {
    width: 100%;
  }
}

/* ----------------------------------------------- CSS Card Form End */

/* CSS Card Start ------------------------------------------------- */

.spp-card {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid v.$pure-grey-neutral-300;
  background: v.$white-color;

  .title {
    color: v.$black-color;
    font-kerning: none;
    font-feature-settings: 'liga' off;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -0.0225rem;
    @extend .spp-semi-bold;
  }
}

.base-card-title {
  color: v.$black-color;
  font-kerning: none;
  font-feature-settings: 'liga' off;
  font-size: 1.125rem;
  line-height: 140%;
  letter-spacing: -0.0225rem;
  @extend .spp-semi-bold;
}

/* Drop_Down_Menu_Start ------------------------------------ */
.custom-dropdown-menu {
  border-radius: 0.625rem;
  width: fit-content;
  overflow: visible;
  border-color: v.$hover-gray-color;
}

.custom-dropdown-menu-header {
  padding-top: 1rem;
  border-bottom: 1px solid v.$black-color;
  padding-bottom: 3px;
  font-size: 16px;
  margin-left: 1rem;
  margin-right: 1rem;
  @extend .spp-bold;
  @extend .custom-item-sizing;
}

.custom-dropdown-menu-item {
  border: none;
  width: 100%;
  min-width: 200px;
  max-width: 320px;
  background-color: v.$white-color;
  text-align: left;
  border-radius: 0.625rem;
  padding: 0.625rem 1rem;
  @extend .spp-regular;
  @extend .custom-item-sizing;
}

.custom-item-sizing {
  min-width: 200px;
  max-width: 400px;
}

.custom-dropdown-menu-item:hover {
  background-color: v.$off-white-color;
}

.custom-dropdown-menu-item:focus {
  outline: none;
  background-color: v.$hover-gray-color;
}

.custom-dropdown-menu-remove {
  color: v.$red-color;
}

/* -------------------------------------- Drop_Down_Menu_End */

/* Drop_Down_Start ----------------------------------------- */
.custom-dropdown {
  padding: 0 0.75rem 0 0.875rem;
  width: 100%;
  height: 2.5rem;
  border-radius: 0.625rem;
  background-color: v.$dropdown-base-color;
  color: v.$dropdown-text-color;
  font-size: 0.875rem;
  line-height: 1.125rem;
  border: none;
  @extend .spp-regular;
  // Hide the default chevron
  -webkit-appearance: none;
  -moz-appearance: none;
  // Add the custom chevron
  background-image: url('/assets/icons/light/solid/drop-down.svg');
  background-position-x: calc(100% - 0.7rem);
  background-position-y: center;
  background-repeat: no-repeat;
  margin-bottom: 1.5rem;
  background-size: 1.5rem;
}

.custom-dropdown:focus {
  color: v.$black-color;
  outline: none;
  border: 1px solid v.$black-color;
  background-color: v.$dropdown-base-color;
  box-shadow: 0 0 1px 1px v.$extra-light-grey-color;
}

.custom-dropdown.has-error {
  border: 1px solid v.$textfield-error-red-color;
}

.custom-dropdown:enabled:hover {
  background-color: v.$dropdown-hover-color;
  border: 1px solid v.$dropdown-hover-border-color;
  color: v.$dropdown-hover-text-color;
  @extend .spp-regular;
}

.custom-dropdown:disabled {
  background-color: v.$dropdown-base-color;
}

.custom-dropdown:disabled,
.custom-textfield:disabled,
.custom-switch-input:disabled,
.custom-slider.disabled,
textarea.custom-text-area:disabled {
  color: v.$mid-grey-color;
}

.custom-dropdown:disabled:hover,
.custom-textfield:disabled:hover,
.custom-switch-input:disabled:hover,
.custom-slider.disabled:hover,
textarea.custom-text-area:disabled {
  cursor: not-allowed;
}

.custom-dropdown-label {
  font-size: 0.8rem;
  line-height: 1rem;
  color: v.$black-color;
  @extend .spp-medium;
}

/* ------------------------------------------- Drop_Down_End */

/* No_Results_Start ----------------------------------------- */

.no-results-container {
  padding-top: 11.56rem;
  background-color: v.$cool-neutral-100;
  border: 1px solid v.$cool-neutral-200;
  border-radius: 0.5rem;
  height: 38rem;
  display: block;
}

.no-results-title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-align: center;
  color: v.$primary-text-color;
  font-weight: 600;
}

.no-results-body {
  font-size: 1rem;
  line-height: 1.125rem;
  text-align: center;
  color: v.$cool-neutral-600;
  word-wrap: break-word;
  @extend .spp-regular;
}
.no-results-body > :first-child {
  margin-top: 0.5rem;
  &:empty {
    display: none;
  }
}

.no-results-button {
  margin: 1.5rem 0 0 0;
}

.no-results-button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 1.5rem;

  button {
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    padding: 0.5rem 1rem !important;
  }
}

/* ------------------------------------------- No_Results_End */

/* Edit_Section_Start ----------------------------------------- */

.custom-edit-section-title {
  font-size: 1rem;
  color: v.$primary-text-color;
  @extend .spp-semi-bold;
}

.custom-edit-section-description {
  font-size: 0.875rem;
  color: v.$extra-dark-gray-color;
  @extend .spp-regular;
}

.custom-edit-section-button-container {
  display: block;
}

.custom-edit-section-button-container .custom-button {
  margin: 0 0.5rem 0 0;
}

.custom-section-container {
  position: relative;
  margin-top: 0.5rem;
}

.custom-section-header {
  display: block;
  width: 100%;
}

.custom-section-body {
  display: block;
  width: 100%;
}

.custom-section-header-button-container {
  display: inline-block;
  float: right;
}

.custom-section-list-title {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5rem;
  color: v.$primary-text-color;
  @extend .spp-bold;
}

/* ------------------------------------------- Edit_Section_End */

/* Tooltip_Start ----------------------------------------- */

.inline-tooltip {
  height: 0.75rem;
  width: 0.75rem;
}

.inline-tooltip:focus {
  outline: none;
}

.datatable-action-button img {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.inline-tooltip img {
  height: 100%;
  width: 100%;
  opacity: 0.5;
}

.inline-tooltip {
  outline: none;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 0.25rem;
}

.inline-tooltip:hover img {
  opacity: 1;
}

/* ------------------------------------------- Tooltip_End */

/* Alert_Start ----------------------------------------- */

.custom-alert {
  @extend .label-md;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: v.$mid-grey-color;
  color: v.$light-text-color;
}

.custom-alert-icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 1rem;
}

.custom-alert.custom-alert-danger {
  background-color: v.$red-color;
  color: v.$light-text-color;
}

.custom-alert.custom-alert-success {
  background-color: v.$green-color;
  color: v.$light-text-color;
}

.warning-message {
  margin: 0.5rem 1.5rem;
  padding: 0.75rem 1rem;
  background-color: v.$yellow-color-80;
  border-radius: 0.5rem;
  text-align: left;
  color: v.$primary-text-color;
  @extend .spp-semi-bold;
}

.warning-message .tooltip-container {
  float: right;
}

/* ------------------------------------------- Alert_End */

/* Popper_Start --------------------------------------------- */

popper-content div.custom-popper-content {
  border: none;
  border-radius: 0.625rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  z-index: 100;
}

.popper-badge {
  background-color: v.$cool-neutral-700;

  span {
    color: v.$white-color;
  }

  .title {
    font-weight: 600;
  }
}

.bs-popover-top {
  > .popover-arrow {
    &::after {
      border-top-color: v.$cool-neutral-700 !important;
    }
  }
}

.look-ahead-popper {
  box-shadow: 0px 2px 8px rgba(17, 24, 39, 0.07) !important;
  border: none !important;
  width: 15.8rem !important;
  padding: 0.5rem 0 !important;
  margin-top: 0.5rem !important;

  .ngxp__arrow::before {
    content: none !important;
  }
}

.look-ahead-popper-full-width {
  border: none !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 0.125rem 0.5rem rgba(17, 24, 39, 0.07) !important;
  margin: 1rem 0 !important;
  padding: 0.5rem 0 !important;
  width: 100%;
  transform: translateY(-0.5rem) !important;
}

.ngxp__container > .ngxp__arrow {
  display: none;
}

/* ----------------------------------------------- Popper_End */

/* Icons_Start --------------------------------------------- */

.icon-size-1 {
  height: 0.25rem;
  width: 0.25rem;
}

.icon-size-2 {
  height: 0.5rem;
  width: 0.5rem;
}

.icon-size-3 {
  height: 1rem;
  width: 1rem;
}

.icon-size-4 {
  height: 1.5rem;
  width: 1.5rem;
}

.icon-size-5 {
  height: 3rem;
  width: 3rem;
}

/* ----------------------------------------------- Icons_End */

// Datatable customization

.sorting-icon {
  filter: brightness(0) invert(1);
  opacity: 100% !important;
}

.custom-datatable-header span.sorting-icon {
  display: none;
}

.custom-datatable-header.sorting_asc {
  text-decoration: underline;
}

.custom-datatable-header.sorting_desc {
  text-decoration: underline;
}

.custom-datatable-footer .position-text-container {
  display: none;
}

.custom-datatable-footer .pagination-container {
  display: flex;
  justify-content: flex-start;
}

.min-height-10 {
  min-height: 10rem;
}

// Material Calendar
mat-calendar.mat-calendar.date-range-picker {
  font-family: 'DM Sans', sans-serif;
}

mat-calendar.date-range-picker .mat-calendar-period-button {
  flex-grow: 1;
  order: 1;
}

mat-calendar.date-range-picker .mat-calendar-previous-button {
  order: 0;
}

mat-calendar.date-range-picker .mat-calendar-next-button {
  order: 2;
}

mat-calendar.date-range-picker .mat-calendar-spacer {
  display: none;
}

mat-calendar.date-range-picker .mat-button-wrapper span {
  //@extend .dm-sans-medium;
  @extend .f14px;
}

mat-calendar.date-range-picker .mat-calendar-body-label {
  border-top: none;
  opacity: 0;
}

mat-calendar.date-range-picker .mat-calendar-body-label[colspan='7'] {
  display: none;
}

mat-calendar.date-range-picker .mat-calendar-body-cell-content {
  //@extend .dm-sans-medium;
  @extend .f12px;
}

mat-calendar.date-range-picker .mat-calendar-body-in-range .mat-calendar-body-cell-content {
  color: white;
}

mat-calendar.date-range-picker
  .mat-calendar-body-in-range
  .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: white;
}

mat-calendar.date-range-picker .mat-calendar-body-in-range::before {
  background: v.$primary-color;
}

mat-calendar.date-range-picker .mat-calendar-table-header-divider {
  display: none;
}

mat-calendar.date-range-picker .mat-calendar-body-cell {
  border-top: none;
}

mat-calendar.date-range-picker tr th {
  //@extend .dm-sans-medium;
  @extend .f12px;
  border-top: none;
  border-bottom: none;
}

// dynamic card
.dynamic-card {
  background-color: v.$off-white-color;
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
  height: 100%;
}

.dynamic-card.clickable {
  cursor: pointer;
}

.dynamic-card.clickable:hover {
  text-decoration: underline;
}

.red-text {
  color: v.$red-color;
}

// google address auto fill
div.pac-container {
  z-index: 9999;
}

// Table

.restricted {
  color: v.$cool-neutral-400 !important;

  &:hover {
    cursor: not-allowed !important;
  }

  .sorting-icon {
    filter: invert(79%) sepia(9%) saturate(362%) hue-rotate(179deg) brightness(81%) contrast(91%) !important;

    &:hover {
      cursor: not-allowed !important;
    }
  }
}

// cursors

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

// accordions

.accordion-chevron {
  height: 1.25rem;
  width: 1.25rem;
  transition: transform 0.3s, filter 0.3s, color 0.3s;
  display: flex;
  margin-bottom: auto;

  &.expanded {
    transform: rotate(0deg);
    filter: invert(0%) sepia(0%) saturate(18%) hue-rotate(293deg) brightness(95%) contrast(105%) !important;
  }

  &.collapsed {
    transform: rotate(180deg);
    filter: invert(23%) sepia(24%) saturate(558%) hue-rotate(181deg) brightness(90%) contrast(88%) !important;
  }
}

// Scroll To Top Reference
.scroll-top {
  position: absolute;
  top: 0;
}
