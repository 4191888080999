// Metropolis

$metropolis-thin: '/assets/fonts/metropolis/Metropolis-Thin.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-thin) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 100;
}

$metropolis-thin-italic: '/assets/fonts/metropolis/Metropolis-ThinItalic.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-thin-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 100;
}

$metropolis-extra-light: '/assets/fonts/metropolis/Metropolis-ExtraLight.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-extra-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 200;
}

$metropolis-extra-light-italic: '/assets/fonts/metropolis/Metropolis-ExtraLightItalic.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-extra-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 200;
}

$metropolis-light: '/assets/fonts/metropolis/Metropolis-Light.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-light) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}

$metropolis-light-italic: '/assets/fonts/metropolis/Metropolis-LightItalic.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-light-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 300;
}

$metropolis-regular: '/assets/fonts/metropolis/Metropolis-Regular.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-regular) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

$metropolis-regular-italic: '/assets/fonts/metropolis/Metropolis-Italic.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-regular-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

$metropolis-medium: '/assets/fonts/metropolis/Metropolis-Medium.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-medium) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 500;
}

$metropolis-medium-italic: '/assets/fonts/metropolis/Metropolis-MediumItalic.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-medium-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 500;
}

$metropolis-semibold: '/assets/fonts/metropolis/Metropolis-SemiBold.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-semibold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 600;
}

$metropolis-semibold-italic: '/assets/fonts/metropolis/Metropolis-SemiBoldItalic.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-semibold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 600;
}

$metropolis-bold: '/assets/fonts/metropolis/Metropolis-Bold.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

$metropolis-bold-italic: '/assets/fonts/metropolis/Metropolis-BoldItalic.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

$metropolis-extra-bold: '/assets/fonts/metropolis/Metropolis-ExtraBold.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-extra-bold) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 800;
}

$metropolis-extra-bold-italic: '/assets/fonts/metropolis/Metropolis-ExtraBoldItalic.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-extra-bold-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 800;
}

$metropolis-black: '/assets/fonts/metropolis/Metropolis-Black.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-black) format('truetype');
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

$metropolis-black-italic: '/assets/fonts/metropolis/Metropolis-BlackItalic.otf';
@font-face {
  font-family: 'Metropolis';
  src: url($metropolis-black-italic) format('truetype');
  font-display: auto;
  font-style: italic;
  font-weight: 900;
}
