@use 'font' as f;
@use 'variables' as v;

:root {
  --reactive-table-pagination-active-color: #{v.$primary-blue-color};

  table.reactive-table {
    position: unset;
    border-collapse: separate !important;
  }

  .pagination-container {
    padding: 2rem 0 0 0 !important;
  }
}
